import gtag, { gtag4Event, GtagConfig } from '../gtag';
import { InitialLandingPage } from '../utils/retrieveInitialLandingPage';
import { trackEventFacebook } from './facebook';

export function trackCheckout(tripId: number, eventCategory: 'checkout' | 'instabook checkout'): void {
  gtag('event', 'Click to checkout', {
    event_category: eventCategory,
  });
  trackEventFacebook('InitiateCheckout', { content_ids: [tripId] });
}

export function trackRegistration() {
  gtag('event', 'New Registration', {
    event_category: 'Authentication',
  });
  trackEventFacebook('CompleteRegistration');
}

type PlausibleProps = Record<string, string | number | boolean>;

/**
 * Sends an event to Plausible Analytics
 * @param eventName event type
 * @param config event parameters (custom props)
 */
function plausibleEvent(
  eventName: string,
  props: PlausibleProps = {},
): void {
  const { plausible } = window;
  if (plausible) {
    // Prepend the event name to the config keys to make it easier to locate params in Plausible
    const prependedProps = Object.fromEntries(
      Object.entries(props).map(([key, value]) => [`${eventName}_${key}`, value]),
    );
    plausible(eventName, { props: prependedProps });
  }
}

/**
 * Sends an event to multiple analytics platforms (currently GA4 and Plausible)
 * @param eventName event type
 * @param config event parameters (custom params)
 */
export function trackEvent(
  eventName: string,
  config: GtagConfig = {},
): void {
  // Send to GA4
  gtag4Event(eventName, config);

  // Send to Plausible
  plausibleEvent(eventName, config as PlausibleProps);
}

export type TrackPurchaseEventParams = {
  transactionId: number;
  bookingTotal: number;
  currency: string;
  locale: string;
  boatId: string;
  boatHeadline: string;
  initialLandingPage?: InitialLandingPage;
  isInstabook?: boolean;
  isDirectBooking?: boolean;
};
/**
 * Send the purchase event via Gtag GTM and onwards to Google Analytics
 * @param params Booking transaction details and locale
 */
export function trackPurchaseEvent({
  transactionId,
  bookingTotal,
  currency,
  locale,
  boatId,
  boatHeadline,
  initialLandingPage,
  isInstabook,
  isDirectBooking,
}: TrackPurchaseEventParams) {
  let purchaseType = isInstabook ? 'marketplace_instabook' : 'marketplace';
  if (isInstabook && isDirectBooking) { purchaseType = 'book-now-direct'; }

  trackEvent('purchase', {
    transaction_id: transactionId,
    affiliation: 'GMB Booking',
    event_category: 'checkout',
    value: bookingTotal,
    currency,
    tax: 0,
    tax_rate: 0,
    shipping: 0,
    purchase_type: purchaseType,
    locale,
    items: [{
      id: boatId,
      item_id: boatId,
      item_name: boatHeadline,
      quantity: 1,
      price: bookingTotal,
    }],
    p1: initialLandingPage?.url,
    p2: initialLandingPage?.referrer,
    p3: initialLandingPage?.gclid,
    p4: initialLandingPage?.utmValues?.utm_source,
    p5: initialLandingPage?.utmValues?.utm_medium,
    p6: initialLandingPage?.utmValues?.utm_campaign,
    p7: initialLandingPage?.utmValues?.utm_term,
    p8: initialLandingPage?.utmValues?.utm_content,
  });
}
