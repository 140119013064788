import React, { FC, useEffect } from 'react';
import { parse, stringify } from 'query-string';
import { RouteComponentProps } from 'react-router-dom';

import isDarkCode from 'src/common/utils/isDarkCode';
import IHeartMediaTag from 'src/common/3rdParty/iheartmedia';
import { microsoftEvent } from 'src/common/3rdParty/microsoft';
import { YelpConversionPixel } from 'src/common/3rdParty/yelp';
import useLocale from 'src/common/hooks/useLocale';
import { trackPurchaseEvent } from 'src/common/tracking';
import retrieveInitialLandingPage, { clearInitialLandingPage } from 'src/common/utils/retrieveInitialLandingPage';
import { ImmutableTrip } from '../../../../types/trips/Trips';
import { PATHS } from '../../../../common/constants';
import { userIsRenter } from '../../../helpers';
import TripPanel from '../../presentation/TripPanel';
import ConfirmedBooking from '../../ConfirmedBooking';
import TopScroll from '../../../../common/components/TopScroll';
import { preserveSearch } from '../../../../common/utils/routing';
import CancelActiveInquiriesModal from '../../CancelActiveInquiriesModal';
import gtag from '../../../../common/gtag';
import { trackEventFacebook } from '../../../../common/tracking/facebook';
import content, { getMessage } from '../content';
import PurchaseEventTestButton from '../../PurchaseEventTestButton';

type Props = {
  trip: ImmutableTrip;
  location: RouteComponentProps;
  history: RouteComponentProps;
};

const Confirmed: FC<Props> = ({
  trip,
  location,
  history,
}) => {
  const { session_id: sessionId, ...restQuery } = parse(location.search);
  const isRenter = userIsRenter(trip);

  const locale = useLocale();

  useEffect(
    // This bit of code is to tell Google Analytics/ FB Pixel about conversions
    // after successful confirmation. It consumes the querystring parameter,
    // notifies google, and then goes to a location without that querystring
    // parameter.
    () => {
      if (!sessionId) {
        return;
      }
      const transaction = trip.get('transaction');
      const isInstabook = trip.get('is_instabook');
      const isDirectBooking = trip.get('direct_booking');
      const bookingTotal = transaction?.getIn(['amounts', 'renter_booking_amount']) || 0;
      const currency = transaction?.getIn(['amounts', 'renter_currency', 'code']);
      const transactionId = trip.get('pk');
      const boatId = trip.getIn(['boat', 'id']);
      const boatHeadline = trip.getIn(['boat', 'headline']);
      const initialLandingPage = retrieveInitialLandingPage();

      trackPurchaseEvent({
        transactionId,
        bookingTotal,
        currency,
        locale,
        boatId,
        boatHeadline,
        initialLandingPage,
        isInstabook,
        isDirectBooking,
      });

      // After successful purchase, clear the initial landing page data.
      // The data is only stored to record analytics data around the purchase flow.
      clearInitialLandingPage();

      // Send conversion event to Google Ads when on Production.
      if (!isDarkCode()) {
        gtag('event', 'conversion', {
          send_to: 'AW-991706362/w9v0CKTHxZ8BEPr58NgD',
          value: bookingTotal,
          currency,
          transaction_id: sessionId,
        });
      }

      // Send conversion event to Facebook.
      trackEventFacebook('Purchase', {
        content_ids: [trip.get('pk')],
        value: bookingTotal,
        currency,
      });

      // Send conversion event to Pinterest.
      if (typeof window !== 'undefined' && window.pintrk) {
        window.pintrk('track', 'checkout', {
          currency,
          order_quantity: 1,
          value: bookingTotal,
          line_items: [{
            product_id: trip.getIn(['boat', 'id']),
          }],
        });
      }

      // Send conversion event to Microsoft Bing.
      microsoftEvent('BookingConfirmed', {
        event_label: 'BoatBooked',
        event_value: bookingTotal,
        event_category: 'Purchase',
        revenue_value: bookingTotal,
        currency,
      });

      // Consume the session ID, since this is the only thing we care to use it for
      history.replace({ ...location, search: stringify(restQuery) });
    },
    // TODO: figure out whether this or an empty array makes more sense.
    // Technically, we want this as a sort of didMount, but it will probably behave
    // that way in effect, especially since it's consuming the session_id
    [history, location, trip, sessionId, restQuery, locale],
  );
  const tripState = trip.getIn(['state', 'state']);
  const showCancelModal = parse(location.search)['show-cancel-inquiries'];

  return (
    <TripPanel
      trip={trip}
      subheader={content.title[tripState]}
      intro={getMessage(trip)}
      classNameModifier="confirmed"
      backLocation={preserveSearch(PATHS.INBOX, location)}
    >

      {/* Trigger purchase event for iHeartMedia and Yelp, but only if the sessionId search param
          is defined. This only occurs when we return from Stripe */}
      {sessionId && (
        <>
          <IHeartMediaTag event="purchase" value={trip.getIn(['transaction', 'amounts', 'renter_usd_booking_amount'])} orderId={trip.get('pk')} />
          <YelpConversionPixel value={trip.getIn(['transaction', 'amounts', 'renter_booking_amount'])} orderId={trip.get('pk')} currency={trip.getIn(['transaction', 'amounts', 'renter_currency', 'code'])} />
        </>
      )}
      <PurchaseEventTestButton />
      <TopScroll />
      {isRenter && showCancelModal && <CancelActiveInquiriesModal />}
      <ConfirmedBooking trip={trip} />
    </TripPanel>
  );
};

export default Confirmed;
